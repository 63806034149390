.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.firebaseui-idp-google > .firebaseui-idp-text::after{
        font-size: 14px;
        content: "Iniciar con Google";
  }

.firebaseui-idp-google > .firebaseui-idp-text{
    font-size: 0;

}

.firebaseui-idp-google > .firebaseui-idp-text-short ::after{
    font-size: 14px;
    content: "Google";
  }

.firebaseui-idp-google > .firebaseui-idp-text-short {
    font-size: 0;

  }



  .firebaseui-idp-password > .firebaseui-idp-text::after{
    font-size: 14px;
    content: "Iniciar con Correo";
}

.firebaseui-idp-password > .firebaseui-idp-text{
font-size: 0;

}

.firebaseui-idp-password > .firebaseui-idp-text-short ::after{
font-size: 14px;
content: "Correo";
}

.firebaseui-idp-password > .firebaseui-idp-text-short {
font-size: 0;

}


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 0;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  /* margin-left: -20px; */
  transition: all 0.5s;
  width:60px;

}


.sidenav-label{
  display:inline-block;
}
.sidebar .sidenav-label {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 1s;
}

.sidebar.is-open .sidenav-label {
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
  transition: all 1s;
}




.sidebar.is-open {
  min-width: 100%;
  margin-left: 0;
  transition: .5s;

}

.sidebar.is-open, a {
  display: block;
}


.sidebar-header {
  background: #313b4c;
  color: #adb5bd;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show, 
.collapsing {
  background: #313b4c;
}

.nav-item {
  
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.sidebar .nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: .2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.carousel-item-content {
  min-width: 150px;
  min-height: 100px;
  max-width: 80%;
  padding: 20px;
  /* max-height: 100px; */
  overflow: hidden;
  display: block;
  /* border: 1px solid red; */
  margin: auto;
}

.si-no-container {
  
}

.si-no-container > button{
  width: 60px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 45px;
  width: 45px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 25px;
  color: #1762A0;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 25px;
  color: #1762A0;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 575.98px) {
  body {
    overflow:  hidden;
  }
  
  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
    
  }
  
  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }
  
  .sidebar.is-open > .sidebar-header button {
    display: block;
  }
  
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

/* table td[rowspan] {
  height:auto;
  white-space: nowrap;
  position:relative;
}

table td[rowspan] > div {
  transform: rotate(-90deg);
  position:absolute;
  left:0;
  right:0;
  top: 10px;
  margin:auto;
} */

div.vertical
{
 margin-left: -85px;
 margin-right: -85px;
 width: auto;
 height: auto;
 transform: rotate(-90deg);
 -webkit-transform: rotate(-90deg); /* Safari/Chrome */
 -moz-transform: rotate(-90deg); /* Firefox */
 -o-transform: rotate(-90deg); /* Opera */
 -ms-transform: rotate(-90deg); /* IE 9 */
 white-space: nowrap;
 padding: 10px;
 
}

td.vertical
{
background-color: #fff;
 height: 150px;
 line-height: 14px;
 padding-bottom: 20px;
 text-align: left;
 border:2px solid black;

}

/* table td[rowspan] {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 1%;
} */

tr.section-top {
  border-top:2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
}

tr.section-middle {
  border-right: 2px solid black;
}

tr.section-bottom {
  border-bottom:2px solid black;
  border-right: 2px solid black;
}

.opcion-multiple {
  min-width: 250px;
}


.preguntaEscondida {
  display: none;
}

.completado {
  color:greenyellow;
}

.seleccion-multiple{
  margin-top:0px !important;
  border:gainsboro 1px solid;
  width:100%;
  padding: 8px;
}

.contenedor-opcion {
  margin-top: 15px;
  text-align: right;
}

.opcion-check{
  margin-left: 5px;
  margin-right:2px
}

.opcion-help {
  padding-right:10px
}





